import React from 'react'
import { Link } from 'gatsby'
import { Spring } from 'react-spring'
import { delay } from 'delay'

import Layout from '../components/layout'
import Image from '../components/image'

const IndexPage = () => (
  <div class="entirePage">
  <Layout />
  </div>
)

export default IndexPage
